<template>
  <WrapperStyled v-away="closeModal">
    <div @click="openModal">
      <InfoIcon size="1x" />
    </div>
    <transition name="slide-fade">
      <Modal class="modal" v-if="modalExpanded">
        <template v-slot:header>
          <div ref="title">{{ asset.name | capitalize }}</div>
          <XIcon @click="closeModal('Icon')" />
        </template>
        <template v-slot:main>
          <TableStyled ref="serviceDetails">
            <div class="engineSection sectionDivider">
              <span class="sectionHeader">{{ $tc('engine', 1) }}</span>
              <div class="details">
                <KeyValueV2Atom :label="$tc('model')" :value="engines ? engines.model.name : '-'" />
                <KeyValueV2Atom :label="$tc('serialNumber')" :value="engines ? engines.serialNumber : '-'" />
              </div>
            </div>
            <div class="sectionDivider">
              <span class="sectionHeader">{{ $t('maintenance.serviceAlerts.nextService') }}</span>
              <BasicTable :columns="maintenanceColumns" :rows="maintenanceRows" />
            </div>
            <div class="sparePartsSection">
              <span class="sectionHeader">{{ $tc('maintenance.spareParts', 2) }}</span>
              <div v-if="!sparePartsData.length" class="noData">{{ $t('messages.noSpareParts') }}</div>
              <BasicTable :columns="sparePartsColumns" :rows="sparePartsRows" v-else />
            </div>
          </TableStyled>
        </template>
        <template v-slot:footer>
          <ActionsStyled>
            <ButtonBorderedStyled @click="closeModal('Button')">
              {{ $t('actions.close') }}
            </ButtonBorderedStyled>
            <ButtonSolidStyled @click="copyToClipboard">
              {{ $t('actions.copyInformation') }}
            </ButtonSolidStyled>
          </ActionsStyled>
        </template>
      </Modal>
    </transition>
  </WrapperStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { XIcon, InfoIcon } from 'vue-feather-icons'
import { directive as onClickaway } from 'vue-clickaway'
import { ButtonBordered as ButtonBorderedStyled, ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import localesMixin from '@/mixins/locales'
import Modal from '@/components/Atomic/Atoms/Modal'
import BasicTable from '@/components/Atomic/Atoms/BasicTableAtom'
import { getShortDate } from '@/utils/time'
import { FlashMessages } from '@common/singletons'
import KeyValueV2Atom from '@/components/Atomic/Atoms/KeyValueV2Atom.vue'

const WrapperStyled = styled('div')`
  display: flex;
  position: relative;
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.3, 1);
  }
  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
  .feather {
    cursor: pointer;
    align-self: center;
    display: flex;
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
      color: ${({ theme }) => theme.colors.primaryActive};
    }
  }
  .modal {
    width: 500px;
    position: absolute;
    right: 1.5rem;
    top: -1rem;
    display: flex;
    flex-direction: column;
    > header {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      grid-template-columns: 5rem 1fr;
      padding-left: 1rem;
      .feather {
        color: ${({ theme }) => theme.colors.primary};
        &:hover {
          color: ${({ theme }) => theme.colors.primaryActive};
        }
      }
    }
    > main {
      display: block;
      padding: 0.5rem;
    }
  }
`
const TableStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .engineSection {
    > .details {
      display: flex;
      padding: 0.5rem;
      gap: 1rem;
      > div {
        > .label {
          font-weight: bold;
        }
        > .value {
          font-size: 12px;
          color: ${({ theme }) => theme.colors.navFontNormal};
        }
      }
    }
  }
  .sectionDivider {
    padding-bottom: 0.5rem;
    border-bottom: 0.75px solid ${props => chroma(props.theme.colors.navFontNormal).alpha(0.2).css()};
  }
  .sparePartsSection {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
    table {
      > tbody > tr:nth-child(even) {
        background-color: ${({ theme }) => (theme.isDark ? theme.colors.atomic.healthStatusIconBG : theme.colors.whiteSmokeDark)};
      }
    }
    padding-bottom: 0.5rem;
    > .noData {
      display: flex;
      font-size: 12px;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
  }
  .sectionHeader {
    font-size: 14px;
    font-weight: bold;
    padding: 0 0.5rem;
  }
`
const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
`
export default {
  mixins: [localesMixin],
  props: {
    data: {
      type: Array,
      require: true,
    },
    asset: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      modalExpanded: false,
    }
  },
  directives: {
    away: onClickaway,
  },
  components: {
    InfoIcon,
    WrapperStyled,
    Modal,
    XIcon,
    BasicTable,
    TableStyled,
    ButtonBorderedStyled,
    ActionsStyled,
    KeyValueV2Atom,
    ButtonSolidStyled,
  },
  computed: {
    maintenanceColumns() {
      return [
        { id: 'header_label', label: this.$tc('description', 1) },
        { id: 'header_interval', label: this.$tc('maintenance.serviceAlerts.interval', 1) },
        { id: 'header_date', label: this.$t('maintenance.serviceAlerts.serviceDate') },
      ]
    },
    maintenanceRows() {
      return this.data.map(maintenance => {
        return [
          {
            id: `label`,
            value: maintenance?.label ?? '-',
            headerId: 'header_label',
          },
          {
            id: `interval`,
            value: maintenance?.timeUntilService ?? '-',
            headerId: 'header_interval',
          },
          {
            id: `date`,
            value: maintenance?.serviceDate ? getShortDate(maintenance?.serviceDate, this.getCustomLocale()) : '-',
            headerId: 'header_date',
          },
        ]
      })
    },
    sparePartsColumns() {
      let spareParts = [
        { id: 'header_number', label: this.$t('maintenance.serviceAlerts.number') },
        { id: 'header_description', label: this.$tc('description', 1) },
        { id: 'header_quantity', label: this.$t('maintenance.serviceAlerts.quantity') },
      ]
      if (this.data.length > 1) {
        spareParts.splice(1, 0, { id: 'header_service', label: this.$t('maintenance.serviceAlerts.service') })
      }
      return spareParts
    },
    sparePartsData() {
      return [...this.data.reduce((acc, item) => acc.concat(item.spareParts), [])]
    },
    sparePartsRows() {
      return this.sparePartsData?.map(part => {
        let spareParts = [
          {
            id: `number`,
            value: part.partNumber,
            headerId: 'header_number',
          },
          {
            id: `description`,
            value: part.sparePartNameTranslations.find(e => e.locale === this.uiSettings?.language?.toLowerCase()).label ?? '-',
            headerId: 'header_description',
          },
          {
            id: `quantity`,
            value: part.quantity,
            headerId: 'header_quantity',
          },
        ]

        if (this.data.length > 1) {
          spareParts.splice(1, 0, {
            id: `service`,
            value: part.kit ?? '-',
            headerId: 'header_service',
          })
        }
        return spareParts
      })
    },
    engines() {
      return this.asset.engines[0]
    },
  },
  methods: {
    openModal() {
      this.modalExpanded = true
      this.$matomo?.trackEvent('Buttons', 'Click', `Next-Service-Modal-Open`, 0)
    },
    closeModal(trigger) {
      this.modalExpanded = false
      this.$matomo?.trackEvent('Buttons', 'Click', `Next-Service-Modal-${trigger}-Close`, 0)
    },
    copyToClipboard() {
      const content = this.$refs.serviceDetails
      // Create a range to select the content
      const range = document.createRange()
      range.selectNode(content)
      // Add the range to the current selection
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
      // Execute the copy command
      try {
        const content = document.execCommand('copy')
        if (content) {
          FlashMessages.$emit('success', this.$t('messages.maintenanceCopySuccess'), { timeout: 1500 })
          this.$matomo?.trackEvent('Buttons', 'Click', `Next-Service-Modal-Copy`, 0)
        }
      } catch (err) {
        FlashMessages.$emit('error', `Unable to Copy the content!.`, { timeout: 1500 })
      }
      // Remove the range from the selection
      selection.removeAllRanges()
    },
  },
}
</script>
